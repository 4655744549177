import { takeLatest, put, call } from "redux-saga/effects";

import { LOGIN,FORGOT_PASSWORD,RESET_PASSWORD,VERIFY_OTP} from "./actionTypes";

import {
  loginSuccess,
  loginFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  verifyOTPSuccess,
  verifyOTPFailure
} from "./actions";

import { login,forgotPassword,resetPassword,verifyOTP } from "../../helpers/backend_helper";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';


  function* onResetPassword({ payload: data,navigate }) {
    try {
      const response = yield call(resetPassword,data);
      console.log(response);
      toast(response.message);
      if(response.status===1){
        navigate("/login");
  
         }
  
      yield put(resetPasswordSuccess(response));
    } catch (error) {
    toast(error.response.data.message);
      yield put(resetPasswordFailure(error.response));
    }
  }
  function* onForgotPassword({ payload: data,navigate }) {
    try {
      const response = yield call(forgotPassword,data);
      console.log(response);
      toast(response.message);
      if(response.status===1){
        navigate("/login");
  
         }
  
      yield put(forgotPasswordSuccess(response));
    } catch (error) {
    toast(error.response.data.message);
      yield put(forgotPasswordFailure(error.response));
    }
  }

  // LOGIN
function* onLogin({ payload: data,navigate }) {
  try {
    const response = yield call(login,data);
    console.log(response);
    toast(response.message);
    if(response.status===1){

      navigate("/verification",{state:{hotel_location_id:response.payload._id ,loginData:response.payload}});

      // authLogin({
      //       email: response.payload.email,
      //       locationId:response.payload._id,
      //       locationData:response.payload,
      //       otpVerified:false
      //     });
       }

    yield put(loginSuccess(response.payload));
  } catch (error) {
  toast(error.response.data.message);
    yield put(loginFailure(error.response));
  }
}


//verify OTP

function* onVerifyOTP({ payload: data,authLogin ,token}) {
  try {
    const response = yield call(verifyOTP,data);
    console.log(response);
   // toast(response.message);
    if(response.status===1){
      authLogin({
            email: response.message.email,
            locationId:response.message._id,
            locationData:response.message,
            token:token
          });
       }

    yield put(verifyOTPSuccess(response.message));
  } catch (error) {
  toast(error.response.data.message);
    yield put(verifyOTPFailure(error.response));
  }
}
function* CartSaga() {
  yield takeLatest(LOGIN, onLogin);
  yield takeLatest(VERIFY_OTP, onVerifyOTP);
  yield takeLatest(FORGOT_PASSWORD, onForgotPassword);
  yield takeLatest(RESET_PASSWORD, onResetPassword);


}

export default CartSaga;
