import { Button,Card,CardBody,CardText,CardTitle,CardSubtitle,CardGroup } from 'reactstrap';
import React from 'react';
import { Table } from 'reactstrap';
import hiltonLogo from './hilton.png';
import { useAuth } from '../../hooks/useAuth';
const breadCrumbCss = {
  paddingTop: '10px',
  fontSize: '20px',
  width: 'fit-content',
  paddingLeft: '30px',
  fontWeight:'500'
}

const headingCss = {
  fontSize: '20px',
  width: 'fit-content',
  marginBottom: '20px',
}

const brandCss = {
  width:'33%' ,
  display: 'inline-block',
  width: 'fit-content',
  marginBottom: '20px',
}
function AccountDetails() {
  const { user } = useAuth();

  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>
      <p style={breadCrumbCss}>My Account Details</p>


      <div className='order-history-table' style={{
        backgroundColor: '#FFF',marginTop:'10px', display: 'block', padding: 30
      }}>

        {/* <h5 style={headingCss}>My Account Details</h5> */}


        <div className='location-details'>
        <CardGroup>
  <Card
    style={{
    backgroundColor: '#bfd8eb',
    padding:'0px',
  }}>
  
    <CardBody>
      <CardTitle tag="h6">
        Location:    { user.locationData.customer_name}

      </CardTitle>
      <CardSubtitle
        className="mb-2 text-muted"
        tag="h6"
      >
       <p className='no-margin'>Attn: {user.locationData.first_name} {user.locationData.last_name}</p>
      </CardSubtitle>
    
      <CardText  className="text-muted no-margin">
     <p className='no-margin'>{user.locationData.ship_to_street1} {user.locationData.ship_to_street2}</p> 
     <p className='no-margin'> {user.locationData.ship_to_city}, {user.locationData.ship_to_state} {user.locationData.ship_to_zip}</p>
     <p className='no-margin'> {user.locationData.ship_to_country}</p>

      </CardText>
     
     
    </CardBody>
  </Card>
  <Card

  style={{
    backgroundColor: '#F2F7FB',
    padding:'0px',
  }}>
   
    <CardBody>
      <CardTitle tag="h6">
        Door Lock
      </CardTitle>
      <CardSubtitle
        className="mb-2 text-muted"
        tag="h6"
      >
        {user.locationData.door_lock}

      </CardSubtitle>
      <CardTitle tag="h6">
        Chip
      </CardTitle>
      <CardSubtitle
        className="mb-2 text-muted"
        tag="h6"
      >
        {user.locationData.chip}

      </CardSubtitle>
     
    </CardBody>
  </Card>
  <Card
   style={{
    backgroundColor: '#bfd8eb',
    padding:'0px',
  }}>
   
    <CardBody>
      <CardTitle tag="h6">
        Email Address
      </CardTitle>
      <CardSubtitle
        className="mb-2 text-muted"
        tag="h6"
      >
        {user.locationData.email}
      </CardSubtitle>
      <CardTitle tag="h6">
        Phone Number
      </CardTitle>
      <CardSubtitle
        className="mb-2 text-muted"
        tag="h6"
      >
        {user.locationData.main_phone}
      </CardSubtitle>
      {/* <Button>Change Password</Button> */}
     
    </CardBody>
  </Card>
</CardGroup>

        </div>
      </div>
    </div>
  );
}

export default AccountDetails;
