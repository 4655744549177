import { all, fork } from "redux-saga/effects";

import PostSaga from "./products/saga";
import LoginSaga from "./adminLogin/saga";
import HotelLocationSaga from "./hotelLocations/saga";
import OrdersSaga from "./orders/saga";
import MyCartSaga from "./myCart/saga";


export default function* rootSaga() {
  yield all([fork(PostSaga)]);
  yield all([fork(LoginSaga)]);
  yield all([fork(HotelLocationSaga)]);
  yield all([fork(OrdersSaga)]);
  yield all([fork(MyCartSaga)]);


}
