import { delItem, get, put } from "./api_helper";
import * as url from "./url_helper";
import { post } from "./api_helper";

//Post
export const getPosts = () => get(url.GET_POSTS);
export const addProduct = (data) => post(url.ADD_PRODUCT,data);
export const delProduct=(data)=>delItem(url.DEL_PRODUCT,data);
export const editProduct=(data)=>put(url.EDIT_PRODUCT,data);
export const allocateProducts = (data) => post(url.ALLOCATE_PRODUCT,data);
export const getAllocatedProducts = (token,hotel_location_id) =>
  get(url.GET_ALLOCATED_PRODUCT,  token, {
    params: {
      hotel_location_id: hotel_location_id,
    },
  });
  export const delAllocatedProduct=(data)=>delItem(url.DEL_ALLOCATED_PRODUCT,data);

export const login = (data) => post(url.LOGIN,data);
export const verifyOTP = (data) => post(url.VERIFY_OTP,data);

export const forgotPassword = (data) => post(url.FORGOT_PASSWORD,data);
export const resetPassword = (data) => put(url.RESET_PASSWORD,data);


export const getHotelLocations = () => get(url.GET_HOTEL_LOCATIONS);
export const addLocation = (data) => post(url.ADD_LOCATION,data);
export const delLocation=(data)=>delItem(url.DEL_LOCATION,data);
export const editLocation=(data)=>put(url.EDIT_LOCATION,data);

export const createOrder = (data) => post(url.CREATE_ORDER,data);

export const getOrders = (token,hotel_location_id) => get(
  url.GET_ORDERS,token,{
  params: {
    hotel_location_id: hotel_location_id,
  },
});


//Post
export const getPostDetails = (id) =>
  get(url.GET_POST_DETAILS, {
    params: {
      id: id,
    },
  });


  export const delCartItem=(data)=>delItem(url.DEL_ITEM_CART,data);

  export const addToCart = (data) => post(url.ADD_TO_CART,data);

  export const getCart = (token,hotel_location_id) =>
  get(url.GET_CART,token, {
    params: {
      hotel_location_id: hotel_location_id,
    },
  });