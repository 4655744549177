import {
  GET_HOTEL_LOCATIONS,
  GET_HOTEL_LOCATIONS_SUCCESS,
  GET_HOTEL_LOCATIONS_FAIL,
  ADD_LOCATION,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_FAILURE,
  DEL_LOCATION,
  DEL_LOCATION_SUCCESS,
  DEL_LOCATION_FAILURE,
  EDIT_LOCATION,
  EDIT_LOCATION_SUCCESS,
  EDIT_LOCATION_FAILURE
} from "./actionTypes";

export const getHotelLocations= () => {
  return {
    type: GET_HOTEL_LOCATIONS,
  };
};

export const getHotelLocationsSuccess = (hotelLocations) => {
  return {
    type: GET_HOTEL_LOCATIONS_SUCCESS,
    payload: hotelLocations,
  };
};

export const getHotelLocationsFail = (error) => {
  return {
    type: GET_HOTEL_LOCATIONS_FAIL,
    payload: error,
  };
};

export const addLocation = (data,navigate) => {
  return {
    type: ADD_LOCATION,
    payload: data,
    navigate:navigate,
  };
};

export const addLocationSuccess = (addLocationResponse) => {
  return {
    type: ADD_LOCATION_SUCCESS,
    payload:addLocationResponse,
  };
};

export const addLocationFailure = (error) => {
  return {
    type: ADD_LOCATION_FAILURE,
    payload:error,
  };
};




export const editLocation = (data,navigate) => {
  return {
    type: EDIT_LOCATION,
    payload: data,
    navigate:navigate,
  };
};

export const editLocationSuccess = (editLocationResponse) => {
  return {
    type: EDIT_LOCATION_SUCCESS,
    payload:editLocationResponse,
  };
};

export const editLocationFailure = (error) => {
  return {
    type: EDIT_LOCATION_FAILURE,
    payload:error,
  };
};




export const delLocation = (data,navigate) => {
  return {
    type: DEL_LOCATION,
    payload: data,
    navigate:navigate,
  };
};

export const delLocationSuccess = (delLocationResponse) => {
  return {
    type: DEL_LOCATION_SUCCESS,
    payload:delLocationResponse,
  };
};

export const delProductFailure = (error) => {
  return {
    type: DEL_LOCATION_FAILURE,
    payload:error,
  };
};