import { takeLatest, put, call } from "redux-saga/effects";

import { GET_POSTS, GET_POST_DETAILS,ADD_PRODUCT,DEL_PRODUCT,EDIT_PRODUCT,ALLOCATE_PRODUCTS,GET_ALLOCATED_PRODUCTS,DEL_ALLOCATED_PRODUCT } from "./actionTypes";

import {
  getPostsSuccess,
  getPostsFail,
  getPostDetailsSuccess,
  getPostDetailsFail,
  addProductSuccess,
  addProductFailure,
  delProductFailure,
  delProductSuccess,
  editProductSuccess,
  editProductFailure,
  allocateProductsFailure,
  allocateProductsSuccess,
  getAllocatedProductsSuccess,
  getAllocatedProductsFail,
  delAllocatedProductSuccess,
  delAllocatedProductFailure
} from "./actions";

import { getPosts, getPostDetails,addProduct,delProduct,editProduct,allocateProducts,getAllocatedProducts,delAllocatedProduct } from "../../helpers/backend_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function* onAddProduct({ payload: data,navigate }) {

  
  try {
    const response = yield call(addProduct,data);
    console.log(response);
    toast(response.message);
    if(response.status===1){
      navigate("/dashboard/products");

    }
    yield put(addProductSuccess(response.payload));
  } catch (error) {
    toast(error.response.data.message);
    yield put(addProductFailure(error.response));
  }
}


function* onEditProduct({ payload: data,navigate }) {

  
  try {
    const response = yield call(editProduct,data);
    console.log(response);
    toast(response.message);
    if(response.status===1){
      navigate("/dashboard/products");

    }
    yield put(editProductSuccess(response.payload));
  } catch (error) {
    toast(error.response.data.message);
    yield put(editProductFailure(error.response));
  }
}

function* onDelProduct({ payload: data,navigate }) {

  
  try {
    const response = yield call(delProduct,data);
    console.log(response);
    toast(response.message);
    if(response.status===1){
      navigate(0);

    }
    yield put(delProductSuccess(response.payload));
  } catch (error) {
    toast(error.response.data.message);
    yield put(delProductFailure(error.response));
  }
}

function* onGetPosts() {
  try {
    const response = yield call(getPosts);
    console.log(response);
    yield put(getPostsSuccess(response.payload));
  } catch (error) {
    yield put(getPostsFail(error.response));
  }
}

function* onGetPostDetails({ payload: id }) {
  try {
    const response = yield call(getPostDetails, id);
    yield put(getPostDetailsSuccess(response));
  } catch (error) {
    yield put(getPostDetailsFail(error.response));
  }
}


function* onAllocateProducts({ payload: data,navigate }) {

  
  try {
    const response = yield call(allocateProducts,data);
    console.log(response);
    toast(response.message);
    if(response.status===1){
      navigate(0);

    }
    yield put(allocateProductsSuccess(response.payload));
  } catch (error) {
    toast(error.response.data.message);
    yield put(allocateProductsFailure(error.response));
  }
}

function* onGetAllocatedProducts({ payload: hotel_location_id ,token}) {
  try {
    const response = yield call(getAllocatedProducts, token,hotel_location_id);
    console.log("Allocated Products response",response);
    yield put(getAllocatedProductsSuccess(response));
  } catch (error) {
    yield put(getAllocatedProductsFail(error.response));
  }
}

function* onDelAllocatedProduct({ payload: data,navigate }) {

  
  try {
    const response = yield call(delAllocatedProduct,data);
    console.log(response);
    toast(response.message);
    if(response.status===1){
      navigate(0);

    }
    yield put(delAllocatedProductSuccess(response.payload));
  } catch (error) {
    toast(error.response.data.message);
    yield put(delAllocatedProductFailure(error.response));
  }
}



function* CartSaga() {
  yield takeLatest(GET_POSTS, onGetPosts);
  yield takeLatest(ADD_PRODUCT, onAddProduct);
  yield takeLatest(DEL_PRODUCT, onDelProduct);
  yield takeLatest(EDIT_PRODUCT, onEditProduct);
  yield takeLatest(ALLOCATE_PRODUCTS, onAllocateProducts);
  yield takeLatest(GET_ALLOCATED_PRODUCTS,onGetAllocatedProducts);
  yield takeLatest(DEL_ALLOCATED_PRODUCT,onDelAllocatedProduct);

  yield takeLatest(GET_POST_DETAILS, onGetPostDetails);
}

export default CartSaga;
