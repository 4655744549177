import { Table,Media, CardGroup } from 'reactstrap';
// import reactlogo from '../assets/reactlogo.png';
import {useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import DataTable,{ createTheme } from 'react-data-table-component';
import FilterListIcon from '@mui/icons-material/FilterList';
import React, { useMemo } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { red } from '@mui/material/colors';

import { Button,Card,CardBody,CardText,CardTitle,CardSubtitle } from 'reactstrap';


const breadCrumbCss={
  paddingTop: '10px',
  fontSize: '20px',
  width: 'fit-content',
  paddingLeft: '30px',
  fontWeight:'500'
}

const headingCss={
  fontSize: '20px',
   width: 'fit-content',
   marginBottom:'20px',
}

const brandCss={
  //  width: 'fit-content',
   marginBottom:'20px',
}
function OrderDetail() {


  const columns = useMemo(()=>[
    {
      name: 'Image',
      grow:1.5,
      center:true,
      cell: row =>(<div 
      ><Media
      className='product_image'
        object
        src={row.image_url  ? row.image_url :"https://findicons.com/files/icons/1598/di_black_folder/256/restricted.png"}
    /></div>),
    },
    {
      name: 'item Number',
      selector: row => row.item_number,
      sortable: true,
      grow:2,
      wrap:true
    },
    {
      name: 'Description',
      selector: row => row.product_name,
      sortable: true,
      wrap:true,
      grow:2,
    },
    {
      name: 'Size',
      selector: row => row.size,
     
    },
    {
      name: 'Unit of Measure',
      center:true,
      selector: row => row.unit_of_measure,
     
    },
    {
      name: 'Quantity',     
      center:true,
     selector: row => row.quantity,
    },
 
    {
      name: 'Unit Price',
      right:true,
     selector: row => "$"+row.unit_price.toFixed(2),
    },
    {
      name: 'Extended Price',
      right:true,
   selector: row => "$"+row.total_selling_price.toFixed(2),
    },
  

  ],[],);

  const location = useLocation();
  const { orders, loading } = useSelector((state) => state.OrdersReducer);
  const { user } = useAuth();

  const orderDetails = orders.find(obj => {
    return obj.order_object_id === location.state.orderId;
  });
  const itemList=orderDetails.cart_items;

  const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: red,
            height: 5
        }}
    />
);
  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>
      <p style={breadCrumbCss}>Order Details</p>


        <div className='order-history-table' style={{
          backgroundColor: '#FFF', marginTop: '10px', display: 'block', padding: 30
        }}>

          <div style={brandCss}>
          <CardGroup>  
            <Card  style={{
    backgroundColor: '#bfd8eb',
    padding:'0px',
  }}>
    <CardBody>
      <CardTitle tag="h6">
      Order Id: {orderDetails.order_id}
      </CardTitle>
      <CardSubtitle
        className="mb-2 text-muted"
        tag="h6"
      >
            <p  className='no-margin'>Order Date: {new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit',timeZone: 'GMT'}).format(orderDetails.activity_timeline[0].timestamp)}</p>
      </CardSubtitle>
      </CardBody>
  </Card>
            
            <Card  style={{
    backgroundColor: '#F2F7FB',
    padding:'0px',
  }}>
    <CardBody>
      <CardTitle tag="h6">
        Location:    { user.locationData.customer_name}

      </CardTitle>
      <CardSubtitle
        className="mb-2 text-muted"
        tag="h6"
      >
       <p className='no-margin'>Attn: {user.locationData.first_name} {user.locationData.last_name}</p>
      </CardSubtitle>
    
      <CardText  className="text-muted no-margin">
     <p className='no-margin'>{user.locationData.ship_to_street1} {user.locationData.ship_to_street2}</p> 
     <p className='no-margin'> {user.locationData.ship_to_city}, {user.locationData.ship_to_state} {user.locationData.ship_to_zip}</p>
     <p className='no-margin'> {user.locationData.ship_to_country}</p>

      </CardText>
     
     
    </CardBody>
  </Card>

  <Card
   style={{
    backgroundColor: '#bfd8eb',
    padding:'0px',
  }}>
   
    <CardBody>
      <CardTitle tag="h6">
        Email
      </CardTitle>
      <CardSubtitle
        className="mb-2 text-muted"
        tag="h6"
      >
        {user.locationData.email}
      </CardSubtitle>
      <CardTitle tag="h6">
        Phone Number
      </CardTitle>
      <CardSubtitle
        className="mb-2 text-muted"
        tag="h6"
      >
        {user.locationData.main_phone}
      </CardSubtitle>
     
    </CardBody>
  </Card>
  <Card  style={{
    backgroundColor: '#F2F7FB',
    padding:'0px',
  }}>
    <CardBody>
      <CardTitle tag="h6">
        Door Lock: 

      </CardTitle>
      <CardSubtitle
        className="mb-2 text-muted"
        tag="h6"
      >
       <p className='no-margin'>{user.locationData.door_lock}</p>
      </CardSubtitle>
      <CardTitle tag="h6">
        Chip: 

      </CardTitle>
      <CardSubtitle
        className="mb-2 text-muted"
        tag="h6"
      >
       <p className='no-margin'>{user.locationData.chip}</p>
      </CardSubtitle>
   
    </CardBody>
  </Card>

  </CardGroup>     
          </div>
          <div style={{border: '1px solid #c3c3c3',overflow:'auto'}}>
          <DataTable
          
            columns={columns}
            data={itemList}
            theme=""
        
            fixedHeader
            sortIcon={<FilterListIcon />}
          />
         
          <div className='order-price-section'>
          
           
          <div >
              <p className='order-price-heading'>Order Subtotal</p>
              <p className='order-price'>${orderDetails.accounting.total_selling_price.toFixed(2)}</p>

            </div>
            <div >
              <p className='order-price-heading'>Sales Tax  -  {orderDetails.sales_tax_details.jurisdiction} ( {orderDetails.sales_tax_details.rate}% )</p>
              <p className='order-price'>${orderDetails.accounting.sales_tax_rate.toFixed(2)}</p>

            </div>
            <ColoredLine/>

            <div >
              <p className='order-total-heading'>Order Total</p>
              <p className='order-total-price'>${orderDetails.accounting.total_price.toFixed(2)}</p>

            </div>
          
          
{/*           
          <div style={{ margin: '10px' }}>
              <p style={{ display: 'inline-block',width:'77%',fontSize:'14px' }}>Order Subtotal</p>
              <p style={{ display: 'inline-block',width:'23%' }}>${orderDetails.accounting.total_selling_price}</p>

            </div>
            <div style={{ margin: '10px' }}>
              <p style={{ display: 'inline-block',width:'77%',fontSize:'14px' }}>Sales Tax  -  {user.locationData.tax_item} ( {tax_rate.toFixed(2)}% ) </p>
              <p style={{ display: 'inline-block',width:'23%' }}>${orderDetails.accounting.sales_tax_rate.toFixed(2)}</p>

            </div>
              <div style={{backgroundColor:'lightGray',padding:'10px'}}>
            <p style={{display:'inline-block',width:'77%'}}>Order Total</p>
            <h5 style={{display:'inline-block',width:'23%'}}>${orderDetails.accounting.total_price}</h5>

              </div> */}
          </div>
          </div>
      </div>

      <div>
     
      
    </div>
    </div>
  );
}

export default OrderDetail;
