export const GET_HOTEL_LOCATIONS = "GET_HOTEL_LOCATIONS";
export const GET_HOTEL_LOCATIONS_SUCCESS = "GET_HOTEL_LOCATIONS_SUCCESS";
export const GET_HOTEL_LOCATIONS_FAIL = "GET_HOTEL_LOCATIONS_FAIL";


export const ADD_LOCATION="ADD_LOCATION";
export const ADD_LOCATION_SUCCESS="ADD_LOCATION_SUCCESS";
export const ADD_LOCATION_FAILURE="ADD_LOCATION_FAILURE";

export const DEL_LOCATION="DEL_LOCATION";
export const DEL_LOCATION_SUCCESS="DEL_LOCATION_SUCCESS";
export const DEL_LOCATION_FAILURE="DEL_LOCATION_FAILURE";

export const EDIT_LOCATION="EDIT_LOCATION";
export const EDIT_LOCATION_SUCCESS="EDIT_LOCATION_SUCCESS";
export const EDIT_LOCATION_FAILURE="EDIT_LOCATION_FAILURE";
