//Post
export const GET_POSTS = "/api/v1/items/";
export const ADD_PRODUCT = "/api/v1/items/post-item";
export const DEL_PRODUCT="/api/v1/items";
export const EDIT_PRODUCT="/api/v1/items/post-item";
export const ALLOCATE_PRODUCT="/api/v1/allocation-of-products";



export const GET_ALLOCATED_PRODUCT="/api/v1/allocation-of-products";
export const DEL_ALLOCATED_PRODUCT="/api/v1/allocation-of-products";

export const LOGIN = "/api/v1/hotel-location/login";
export const VERIFY_OTP = "/api/v1/hotel-location/verify-otp";

export const FORGOT_PASSWORD = "/api/v1/hotel-location/forgot-password";
export const RESET_PASSWORD = "/api/v1/hotel-location/reset-password";

export const GET_POST_DETAILS = "/posts";


export const GET_HOTEL_LOCATIONS="/api/v1/hotel-location/list";
export const ADD_LOCATION = "/api/v1/hotel-location/create";
export const DEL_LOCATION="/api/v1/hotel-location/";
export const EDIT_LOCATION="/api/v1/hotel-location/";


export const GET_ORDERS = "/api/v1/order/history";
export const CREATE_ORDER = "/api/v1/order";


export const GET_CART="/api/v1/cart";
export const ADD_TO_CART = "/api/v1/cart";
export const DEL_ITEM_CART = "/api/v1/cart";
