import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE
} from "./actionTypes";

const initialState = {
  orders: [],
  createOrderResponse:{},
  loadingCreateOrder:false,
  loading: false,
  error: {
    message: "",
  }
};

const OrdersReducer = (state = initialState, action) => {


  switch (action.type) {
    case CREATE_ORDER:
      state = { ...state, loadingCreateOrder: true };
      break;
    case CREATE_ORDER_SUCCESS:
      state = { ...state, createOrderResponse: action.payload, loadingCreateOrder: false };

      break;
    case CREATE_ORDER_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingCreateOrder: false,
      };
      break;
    case GET_ORDERS:
      state = { ...state, loading: true };
      break;
    case GET_ORDERS_SUCCESS:
      state = { ...state, orders: action.payload, loading: false };
      break;
    case GET_ORDERS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
   
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default OrdersReducer;
