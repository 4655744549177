import {
    UncontrolledDropdown,Dropdown,
    DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'; import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
 import logo from './aarfidLogo.png';
// import logo from '../../public/logo192.png'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';

import { useAuth } from "../hooks/useAuth";


function Header() {


    const { user, logout } = useAuth();


    const [isOpen, setIsOpen] = React.useState(false);
    const [dropdownOpen, setDropdownOpen] = React.useState(false);

    const toggle = () => {setDropdownOpen(!dropdownOpen);
        }
    return (
        <div className="headerLayout">
            <Navbar color="white" light expand="md">
                <NavbarBrand href="/"> <img src={logo} alt="Logo" /></NavbarBrand>
                <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="justify-content-end" style={{ width: "100%" }} navbar>
                        <NavItem>
                            <NavLink href="/dashboard">Dashboard</NavLink>
                        </NavItem>
                    
                       
                        <NavItem>
                            <NavLink href="/dashboard/order-history">Order History</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/dashboard/place-order">Place Order</NavLink>
                        </NavItem>

<NavItem>
<Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                <DropdownToggle nav caret>
                                Settings
                            </DropdownToggle>
                            <DropdownMenu top>
                                <DropdownItem>
                                    <NavLink href="/dashboard/my-account">My Account</NavLink>
                                </DropdownItem >
                                <DropdownItem divider/>
                                <DropdownItem>
                                    <NavLink onClick={logout}>Logout</NavLink>
                                </DropdownItem>
                                                        
                            </DropdownMenu>
                            
                        </Dropdown>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </div >
    );
}

export default Header;
