import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE
} from "./actionTypes";

const initialState = {
 
  loginResponse: {},
  forgotPasswordResponse: {},
  loadingForgotPassword: false,
  resetPasswordResponse: {},
  loadingResetPassword: false,

  loading: false,
  error: {
    message: "",
  },

  verifyOTPResponse:{},
  loadingVerifyOTP:false,

};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {


    case LOGIN:
      state = { ...state, loading: true };
      break;
    case LOGIN_SUCCESS:
      state = { ...state, loginResponse: action.payload, loading: false };

      break;
    case LOGIN_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;

      case FORGOT_PASSWORD:
        state = { ...state, loadingForgotPassword: true };
        break;
      case FORGOT_PASSWORD_SUCCESS:
        state = { ...state, forgotPasswordResponse: action.payload, loadingForgotPassword: false };
  
        break;
      case FORGOT_PASSWORD_FAILURE:
        state = {
          ...state,
          error: {
            message: "Error",
          },
          loadingForgotPassword: false,
        };
        break;


      case RESET_PASSWORD:
        state = { ...state, loadingResetPassword: true };
        break;
      case RESET_PASSWORD_SUCCESS:
        state = { ...state, resetPasswordResponse: action.payload, loadingResetPassword: false };
  
        break;
      case RESET_PASSWORD_FAILURE:
        state = {
          ...state,
          error: {
            message: "Error",
          },
          loadingResetPassword: false,
        };
        break;



    case VERIFY_OTP:
      state = { ...state, loadingVerifyOTP: true };
      break;
    case VERIFY_OTP_SUCCESS:
      state = { ...state, verifyOTPResponse: action.payload, loadingVerifyOTP: false };

      break;
    case VERIFY_OTP_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingVerifyOTP: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LoginReducer;
