import {
 
  GET_MY_CART,
  GET_MY_CART_SUCCESS,
  GET_MY_CART_FAIL,
  DEL_CART_ITEM,
  DEL_CART_ITEM_FAILURE,
  DEL_CART_ITEM_SUCCESS,
  ADD_TO_CART,
  ADD_TO_CART_FAILURE,
  ADD_TO_CART_SUCCESS

} from "./actionTypes";

const initialState = {
 
  cartResponse:{},
  salesTaxDetails:{},
  myCartProducts:[],
  accounting:{},
  delItemResponse:{},
  addToCartResponse:{},
  loadingAddToCart:false,  
  loadingGetCart: false,
  loadingDelItem:false,
  error: {
    message: "",
  },
};

const MyCartReducer = (state = initialState, action) => {


  switch (action.type) {
    case ADD_TO_CART:
      state = { ...state, loadingAddToCart: true };
      break;
    case ADD_TO_CART_SUCCESS:
      state = { ...state, 
        addToCartResponse: action.payload,
         loadingAddToCart: false };

      break;
    case ADD_TO_CART_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingAddToCart: false,
      };
      break;
    case DEL_CART_ITEM:
      state = { ...state, loadingDelItem: true };
      break;
    case DEL_CART_ITEM_SUCCESS:
      state = { ...state, delItemResponse: action.payload, loadingDelItem: false };

      break;
    case DEL_CART_ITEM_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingDelItem: false,
      };
      break;

        case GET_MY_CART:
          state = { ...state, loadingGetCart: true };
          break;
        case GET_MY_CART_SUCCESS:
          state = { ...state,
            accounting:action.payload.payload[0].accounting,
             myCartProducts:action.payload.payload[0].cart_items,
             cartResponse: action.payload,
             salesTaxDetails: action.payload.payload[0].sales_tax_details,
              loadingGetCart: false };
          break;
        case GET_MY_CART_FAIL:
          state = {
            ...state,
            error: {
              message: "Error",
            },
            loadingGetCart: false,
          };
          break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default MyCartReducer;
