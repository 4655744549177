import axios from "axios";

//apply base url for axios
const REACT_APP_APP_URL = process.env.REACT_APP_APP_URL;

const axiosApi = axios.create({
  baseURL: REACT_APP_APP_URL,
});

axios.interceptors.request.use(function (config) {
  return config;
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url,token, config) {
  return await axiosApi
    .get(url, {
      headers: { 'Authorization': token },
            ...config,

    })
    .then((response) => response.data);
}
export async function post(url, data,config) {
  return await axiosApi
    .post(url,data, {
      ...config,
      headers: { 'Content-Type': 'application/json' }
    })
    .then((response) => response.data);
}

export async function put(url, data,config) {
  return await axiosApi
    .put(url,data, {
      ...config,
      headers: { 'Content-Type': 'application/json' }
    })
    .then((response) => response.data);
}




export async function delItem(url, data,config) {
  return await axiosApi
    .delete(url, {
      ...config,
      headers: { 'Content-Type': 'application/json' },
      data
    })
    .then((response) => response.data);
}