import { takeLatest, put, call } from "redux-saga/effects";

import { GET_ORDERS,CREATE_ORDER} from "./actionTypes";

import {
  getOrdersSuccess,
  getOrdersFail,
  createOrderFailure,
  createOrderSuccess
  
} from "./actions";

import { getOrders,createOrder } from "../../helpers/backend_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function* onCreateOrder({ payload: data,navigate }) {

  
  try {
    const response = yield call(createOrder,data);
    console.log(response);
    toast(response.message);
    if(response.status===1){
      navigate("/dashboard/order-complete");

    }
    yield put(createOrderSuccess(response.payload));
  } catch (error) {
    toast(error.response.data.message);
    yield put(createOrderFailure(error.response));
  }
}

function* onGetOrders({ payload: hotel_location_id,token }) {

  
  try {
    const response = yield call(getOrders,token,hotel_location_id);
    console.log(response);
  
    yield put(getOrdersSuccess(response.payload));
  } catch (error) {
    yield put(getOrdersFail(error.response));
  }
}

function* OrdersSaga() {

  yield takeLatest(GET_ORDERS, onGetOrders);
  yield takeLatest(CREATE_ORDER,onCreateOrder);

}

export default OrdersSaga;
