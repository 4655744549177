import { Button, Input,Media,CardGroup,Card,CardBody,CardTitle,CardSubtitle, CardText,Row,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import React, { useMemo } from 'react';
import { Table } from 'reactstrap';
import hiltonLogo from './hilton.png';
import mycartIcon from '../../assets/mycart.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../assets/reactlogo.png';
import QuantityPicker from './QuantityPicker';

import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  
  import { useEffect } from "react";
  import { useDispatch } from "react-redux";
import { getAllocatedProducts } from '../../store/products/actions';
import { useAuth } from '../../hooks/useAuth';
import { getMyCart } from '../../store/myCart/actions';
import { useSelector } from "react-redux";
import DataTable,{ createTheme } from 'react-data-table-component';
import { Circles } from "react-loader-spinner";
import FilterListIcon from '@mui/icons-material/FilterList';
import { height } from '@mui/system';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Grid from "@mui/material/Grid";
import { addToCart ,delCartItem} from '../../store/myCart/actions';
import { useNavigate } from 'react-router-dom';
import { createOrder } from '../../store/orders/actions';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { useState } from 'react';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { red } from '@mui/material/colors';

const breadCrumbCss = {
  paddingTop: '10px',
  fontSize: '20px',
  width: 'fit-content',
  paddingLeft: '30px',
  fontWeight:'500'
}

const headingCss = {
  fontSize: '20px',
  width: 'fit-content',
  marginBottom: '20px',
}

const brandCss = {
  width: 'fit-content',
  marginBottom: '20px',
}



function PlaceOrder() {

  const { allocatedProductList, loadingGetAllocatedProducts,error } = useSelector((state) => state.PostReducer);
  const { salesTaxDetails,cartResponse,accounting,myCartProducts, loadingGetCart ,loadingAddToCart,loadingDelItem} = useSelector((state) => state.MyCartReducer);

  const notify = () => toast("Your order has been Saved!");
  const { user,logout } = useAuth();

  let dispatch = useDispatch();

  const [state, setState] = useState({isPaneOpen: false});

  const { addToCartResponse } = useSelector((state) => state.MyCartReducer);
  const { createOrderResponse, loadingCreateOrder } = useSelector((state) => state.OrdersReducer);

// Delete Modal
  const [modal, setModal] = useState(false);
  const [delete_cart_item_id, setId] = useState();

  const toggle = (cart_item_id) => {setModal(!modal);
    setId(cart_item_id);}
  

    if(error.message==="Unauthorized"){
      logout();
    }

let navigate =useNavigate();
const handleCompleteOrder = async () => {

    const completeOrderData={
      "cart_id":cartResponse.payload[0].cart_id,
      
  };
  console.log("complete order data",completeOrderData);

   dispatch(createOrder(completeOrderData,navigate));

};


      const handleAddToCart = async (event,allocated_product_id) => {

        event.preventDefault();
        const data = new FormData(event.currentTarget);
          const addToCartData={
            "hotel_location_id":user.locationId,
            "quantity":data.get("quantity"),
            "allocated_product_id":allocated_product_id,
              
        
        };
        console.log("add to cart data",addToCartData);

         dispatch(addToCart(addToCartData,navigate));
      
  };


  const deleteCartItem=(cart_item_id)=>{
    const delData = {
      "cart_item_id": cart_item_id,
      "hotel_location_id":user.locationId,
    };
    console.log(delData);
    dispatch(delCartItem(delData,navigate));   
  }


  const columnsCartProducts = useMemo(()=>[
    
    {
      name: 'Remove',
      ignoreRowClick: true,
      allowOverflow: true,
      grow:0,
      cell: row =>(<div 
      >      
      {/* <Button onClick={()=>deleteCartItem(row.cart_item_id)}><DeleteForeverIcon/></Button> */}
      <Button title='Delete Location' className='manage-locations' onClick={()=>toggle(row.cart_item_id)}><DeleteForeverIcon/></Button>

      </div>),
    },
    {
      name: 'Image',
      ignoreRowClick: true,
      allowOverflow: true,
      grow:2.5,
      center:true,
      cell: row =>(<div 
      ><Media
      className='product_image'
        object
        src={row.image_url  ? row.image_url :"https://findicons.com/files/icons/1598/di_black_folder/256/restricted.png"}
    /></div>),
    },
    {
      name: 'Description',
      selector: row => row.product_name,
      grow:2,
      wrap:true
    },
    {
      name: 'Size',
      selector: row => row.size,
      grow:1,
      wrap:true
    },
    {
      name: 'Unit Of Measure',
      selector: row => row.unit_of_measure,
      grow:1.5,
      center:true,

      wrap:true
    },
    {
      name: 'Quantity',
      center:true,
      selector: row => row.quantity,
    },
    {
      name: 'Unit Price',
      right:true,
      selector: row => "$"+ row.unit_price.toFixed(2),
    },
    {
      name: 'Extended Price',
      right:true,
      grow:1.5,
     selector: row => "$"+row.total_selling_price.toFixed(2),
    },
   
   
  ],[],);

  const columnsAllocatedProducts = useMemo(()=>[
    {
      name: 'Image',
      ignoreRowClick: true,
      allowOverflow: true,
      grow:1.5,
      center:true,
      cell: row =>(<div 
      ><Media
      className='product_image'
        object
        src={row.item_data.image_url  ? row.item_data.image_url :"https://findicons.com/files/icons/1598/di_black_folder/256/restricted.png"}
    /></div>),
    },
    {
      name: 'Item Number',
      selector: row => row.item_data.item_number,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Description',
      selector: row => row.item_data.description,
      sortable: true,
      wrap:true,
      grow:2
    },
    {
      name: 'Size',
      selector: row => row.item_data.size,
      sortable: true,
    },
    {
      name: 'Unit Of Measure',
      selector: row => row.item_data.unit_of_measure,
      center:true,
      sortable: true,
    },
    {
      name: 'Unit Price',
      selector: row => "$"+ row.item_data.item_price.toFixed(2),
      center:true,
      sortable: true,
    },
    {
name:'Add To Cart',
center:true,
cell:row=>(
  <Grid component="form" 
  onSubmit={event => handleAddToCart(event,row.allocated_product_id)} 
  container spacing={2} >
  <Grid  xs={7}>
   
  <Input id='product_quantity'
                 type='number'
                 id='quantity'
                 name='quantity'
                 min={row.min}
                 max={row.max}
                 placeholder='1'
                 defaultValue={row.min}
                />
  </Grid>
  <Grid xs={1}></Grid>
  <Grid  xs={4}>
  <Button 
  outline
  color='primary'
      type='submit'>
        {loadingAddToCart  ? (
            'Loading...'
                 ) : <AddShoppingCartIcon/>}
                   
      </Button>    </Grid>
 
</Grid>
),
    },
//     {
//       name: 'Quantity',
//       grow:5,
//       cell: row =>(<div> 
//       <Grid component="form" 
//       onSubmit={event => handleAddToCart(event,row.allocated_product_id)} 
//       sx={{ mt: 2 }}
//       style={{display:'inline-block'}}>
      
//           <Input id='product_quantity'
//                  type='number'
//                  id='quantity'
//                  name='quantity'
//                  className='quantity_input'
//                  min={row.min}
//                  max={row.max}
//                  placeholder='1'
//                  defaultValue={row.min}/>
//       <Button 
//       type='submit'>
//         {loadingAddToCart  ? (
//             'Loading...'
//                  ) : <AddShoppingCartIcon/>}
                   
//       </Button>   
//       </Grid>
// </div>
//       ),
//     },
   
  ],[],);

  useEffect(() => {
    dispatch(getAllocatedProducts(user.locationId,user.token));
    dispatch(getMyCart(user.locationId,user.locationData.token));

  }, []);
  const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: red,
            height: 5
        }}
    />
);
  return (


    <div style={{ backgroundColor: '#F1F2F4' }}>

<p style={breadCrumbCss}>Place New Order</p>

<div  style={{
          backgroundColor: '#FFF', marginTop: '10px', display: 'block', padding: 30
        }}>
      <SlidingPane
      className="cart-sliding-pane"
      overlayClassName="some-custom-overlay-class"
      isOpen={state.isPaneOpen}
      title="My Cart"
      subtitle=""
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        setState({ isPaneOpen: false });
      }}
    >
      <div>

      {loadingGetCart ? (
          <div className="loader" >
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              timeout={3000} //3 secs
            />
          </div>
        ) : (
          <div >
          <DataTable
            columns={columnsCartProducts}
            data={myCartProducts}
            highlightOnHover
            sortIcon={<FilterListIcon />}
          />
        
        {((myCartProducts.length)>0) ? (
          <div className='cart-amount-section'>
            <ColoredLine/>
            
            <div >
              <p className='cart-price-heading'>Order Subtotal</p>
              <p className='cart-price'>${accounting.total_selling_price.toFixed(2)}</p>

            </div>
            <div >
              <p className='cart-price-heading'>Sales Tax  -  {salesTaxDetails.jurisdiction} ( {salesTaxDetails.rate}% )</p>
              <p className='cart-price'>${accounting.sales_tax_rate? accounting.sales_tax_rate.toFixed(2):accounting.sales_tax_rate}</p>

            </div>
            <div>
              <p className='cart-price-heading'>Order Total</p>
              <p className='cart-total-price'>${accounting.total_price.toFixed(2)}</p>

            </div>

             <div >
             <Button onClick={()=>navigate(-1)} style={{marginRight:'10px',background:'#FE1616'}}>Cancel Order</Button>
             {/* <Button onClick={notify} style={{background:'#29B901',marginLeft:'10px',marginRight:'10px'}}>Save Order</Button> */}
             <Button onClick={handleCompleteOrder} style={{marginLeft:'10px', background:'#0065B3',marginRight:'50px'}}>{loadingCreateOrder  ? (
               'Loading...'
                    ) : (
              'Complete Order'
                      )}</Button>
             <ToastContainer />
             </div>
        
          </div>
            ): ( "")}

       
          </div>
          

)}
      </div>
      <br />


{/* Modal */}

<Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Delete Item</ModalHeader>
        <ModalBody>
          Are you sure you want to Delete this Item from you Cart?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={()=>deleteCartItem(delete_cart_item_id)}>
           {loadingDelItem ? ('Deleting...'):('Yes, Delete')}
                     </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>



    </SlidingPane>
      <div className='order-history-table' >

        <div className='location-details'>
        <CardGroup>
  <Card
    style={{
    backgroundColor: '#bfd8eb',
    padding:'0px',
  }}>
  
    <CardBody>
      <CardTitle tag="h6">
        Location:    { user.locationData.customer_name}

      </CardTitle>
      <CardSubtitle
        className="mb-2 text-muted"
        tag="h6"
      >
       <p className='no-margin'>Attn: {user.locationData.first_name} {user.locationData.last_name}</p>
      </CardSubtitle>
    
      <CardText  className="text-muted no-margin">
     <p className='no-margin'>{user.locationData.ship_to_street1} {user.locationData.ship_to_street2}</p> 
     <p className='no-margin'> {user.locationData.ship_to_city}, {user.locationData.ship_to_state} {user.locationData.ship_to_zip}</p>
     <p className='no-margin'> {user.locationData.ship_to_country}</p>

      </CardText>
     
     
    </CardBody>
  </Card>
  <Card

  style={{
    backgroundColor: '#F2F7FB',
    padding:'0px',
  }}>
   
   <CardBody>
      <CardTitle tag="h6">
        Email
      </CardTitle>
      <CardSubtitle
        className="mb-2 text-muted"
        tag="h6"
      >
        {user.locationData.email}
      </CardSubtitle>
      <CardTitle tag="h6">
        Phone Number
      </CardTitle>
      <CardSubtitle
        className="mb-2 text-muted"
        tag="h6"
      >
        {user.locationData.main_phone}
      </CardSubtitle>
     
    </CardBody>
  </Card>
  <Card
   style={{
    backgroundColor: '#bfd8eb',
    padding:'0px',
  }}>
    <CardBody>
      <CardTitle tag="h6">
        Door Lock
      </CardTitle>
      <CardSubtitle
        className="mb-2 text-muted"
        tag="h6"
      >
        {user.locationData.door_lock}

      </CardSubtitle>
      <CardTitle tag="h6">
        Chip
      </CardTitle>
      <CardSubtitle
        className="mb-2 text-muted"
        tag="h6"
      >
        {user.locationData.chip}
      </CardSubtitle>
     
    </CardBody>
   
  </Card>
</CardGroup>

        </div>
        
        <div style={{ border: '1px solid #c3c3c3'}}>
        
           
      {loadingGetAllocatedProducts ? (
          <div className="loader" >
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              timeout={3000} //3 secs
            />
          </div>
        ) : (
          <DataTable
            columns={columnsAllocatedProducts}
            data={allocatedProductList}
            highlightOnHover
            sortIcon={<FilterListIcon />}
          />
        )}
        </div>
       
      
      </div>

     <Media
      onClick={() => setState({ isPaneOpen: true })}
      type="button"
      className='cart-button'
      
        object
        src={mycartIcon}
    />

</div>
    </div>
  );
}

export default PlaceOrder;
