import { takeLatest, put, call } from "redux-saga/effects";

import {GET_MY_CART,ADD_TO_CART,DEL_CART_ITEM } from "./actionTypes";

import {
 
  getMyCartSuccess,
  getMyCartFail,
addToCartSuccess,
addToCartFailure,
delCartItemFailure,
delCartItemSuccess
} from "./actions";

import { getCart,addToCart,delCartItem } from "../../helpers/backend_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function* onDelCartItem({ payload: data,navigate }) {

  
  try {
    const response = yield call(delCartItem,data);
    console.log(response);
    toast(response.message);
    if(response.status===1){
      navigate(0);

    }
    yield put(delCartItemSuccess(response.payload));
  } catch (error) {
    toast(error.response.data.message);
    yield put(delCartItemFailure(error.response));
  }
}

function* onGetCart({ payload: hotel_location_id ,token}) {
  try {
    const response = yield call(getCart, token,hotel_location_id);
    console.log("Cart Response",response);
    yield put(getMyCartSuccess(response));
  } catch (error) {
    yield put(getMyCartFail(error.response));
  }
}

function* onAddToCart({ payload: data,navigate }) {

  
  try {
    const response = yield call(addToCart,data);
    console.log(response);
    toast(response.message);
    if(response.status===1){
      navigate(0);

    }
    yield put(addToCartSuccess(response.payload));
  } catch (error) {
    toast(error.response.data.message);
    yield put(addToCartFailure(error.response));
  }
}



function* MyCartSaga() {
 
  yield takeLatest(GET_MY_CART, onGetCart);
  yield takeLatest(ADD_TO_CART, onAddToCart);
  yield takeLatest(DEL_CART_ITEM, onDelCartItem);

}

export default MyCartSaga;
