import {

  GET_MY_CART,
  GET_MY_CART_SUCCESS,
  GET_MY_CART_FAIL,
  DEL_CART_ITEM,
  DEL_CART_ITEM_FAILURE,
  DEL_CART_ITEM_SUCCESS,
  ADD_TO_CART,
  ADD_TO_CART_FAILURE,
  ADD_TO_CART_SUCCESS
 
} from "./actionTypes";


export const addToCart = (data,navigate) => {
  return {
    type: ADD_TO_CART,
    payload: data,
    navigate:navigate,
  };
};

export const addToCartSuccess = (addToCartResponse) => {
  return {
    type: ADD_TO_CART_SUCCESS,
    payload:addToCartResponse,
  };
};

export const addToCartFailure = (error) => {
  return {
    type: ADD_TO_CART_FAILURE,
    payload:error,
  };
};
export const delCartItem = (data,navigate) => {
  return {
    type: DEL_CART_ITEM,
    payload: data,
    navigate:navigate,
  };
};

export const delCartItemSuccess = (delProductResponse) => {
  return {
    type: DEL_CART_ITEM_SUCCESS,
    payload:delProductResponse,
  };
};

export const delCartItemFailure = (error) => {
  return {
    type: DEL_CART_ITEM_FAILURE,
    payload:error,
  };
};

export const getMyCart = (hotel_location_id,token) => {
  return {
    type: GET_MY_CART,
    payload: hotel_location_id,
    token:token,
  };
};

export const getMyCartSuccess = (cartResponse) => {
  return {
    type: GET_MY_CART_SUCCESS,
    payload: cartResponse,
  };
};

export const getMyCartFail = (error) => {
  return {
    type: GET_MY_CART_FAIL,
    payload: error,
  };
};
