import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {addProduct} from "../store/products/actions";
import { useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";

import {verifyOTP} from "../store/adminLogin/actions";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { useLocation } from "react-router-dom";
  import { useNavigate} from 'react-router-dom';


export const Verification = () => {

  let hotel_location_id=null;
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if(location.state===null || location.state.hotel_location_id===null){

    navigate('/login');
    }else{
      hotel_location_id=location.state.hotel_location_id;
    }
  }, []);



  const { verifyOTPResponse, loadingVerifyOTP } = useSelector((state) => state.LoginReducer);

    let dispatch = useDispatch();

      const handleSubmit = async (event) => {

        event.preventDefault();
        const data = new FormData(event.currentTarget);
          const verifyOTPData={
            "hotel_location_id":location.state.hotel_location_id,
            "otp":data.get("otp")
        };
        console.log("verify otp data",verifyOTPData);

         dispatch(verifyOTP(verifyOTPData,login,location.state.loginData.token));
      
  };

 

  return (

      
      <Container component="main" maxWidth="xs">

      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Two Step Authenication - OTP
        </Typography>
        <p style={{color:"gray",fontSize:'14px',marginTop:'5px',marginBottom:'0px'}}>
          We've sent a verifcation code to your email 
        </p>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          
          <TextField
            margin="normal"
            required
            fullWidth
            name="otp"
            label="OTP"
            id="otp"
          />

<Button 
onClick={ ()=>navigate("/login")}>
  Entered wrong email? Login Now</Button>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >{loadingVerifyOTP  ? (
            'Loading...'
                 ) : (
           'Verify OTP'
                   )}
          </Button>
          <ToastContainer />

        </Box>
      </Box>
    </Container>
  );
};
