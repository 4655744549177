import logo from './logo.svg';
import './App.css';
import React from 'react';
import { Routes, Route } from "react-router-dom";
import {  Welcome } from "./pages/Welcome";
import { ProtectedLayout } from "./components/ProtectedLayout";
import { HomeLayout } from "./components/HomeLayout";
import store from "./store/index";
import { Provider } from "react-redux";
import Home from './pages/Home';
import OrderHistory from './pages/OrderHistory';
import { LoginPage } from './pages/Login';
import PlaceOrder from './pages/PlaceOrder';
import AccountDetails from './pages/AccountDetails';
import OrderDetail from './pages/OrderDetail';
import OrderComplete from './pages/OrderComplete';
import PlaceNewOrder from './pages/PlaceNewOrder';
import { ForgotPassword } from './pages/ForgotPassword';
import { ResetPassword } from './pages/ResetPassword';
import { Verification } from './pages/Verification';


function App() {
 
        return (

            <Provider store={store}>

            <Routes>
            <Route element={<HomeLayout />}>
              <Route path="/" element={<Welcome />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/verification" element={<Verification />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />

            </Route>
        
            <Route path="/dashboard" element={<ProtectedLayout />}>
            <Route path="" element={<Home />} />
              <Route path="order-history" element={<OrderHistory />} />
              <Route path="order-detail" element={<OrderDetail />} />
              <Route path="place-order" element={<PlaceOrder />} />
              <Route path="my-account" element={< AccountDetails/>} />
              <Route path="order-complete" element={<OrderComplete />} />

            </Route>
        
          </Routes>
        
          </Provider>



    //         < Router >
    //             <div className="App">

    //                 <Header />
    //                 <Routes>
    //                     <Route exact path='/' element={< Home />}></Route>
    //                     <Route exact path='/order-history' element={< OrderHistory />}></Route>
    //                     <Route exact path='/order-detail' element={< OrderDetail />}></Route>
    //                     <Route exact path='/place-order' element={< PlaceOrder />}></Route>
    //                     <Route exact path='/my-account' element={< AccountDetails />}></Route>
    //                     <Route exact path='/order-complete' element={< OrderComplete />}></Route>

    //                 </Routes>
    //                 <Footer />
    //             </div>
    //         </Router >
    //     );
    // } else {
    //     return (

    //         < Router >
    //             <div className="App">

    //                 <Routes>
    //                     <Route exact path='/' element={< Login />}></Route>
    //                 </Routes>
    //             </div>
    //         </Router >
        );

}

export default App;
