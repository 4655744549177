import { Button,CardGroup,Card,CardTitle,CardSubtitle,CardBody,CardText } from 'reactstrap';
import React from 'react';
import { useAuth } from '../../hooks/useAuth';
import AddCardRoundedIcon from '@mui/icons-material/AddCardRounded';
import LocalMallRoundedIcon from '@mui/icons-material/LocalMallRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { Add } from '@mui/icons-material';

import { useNavigate} from 'react-router-dom';

function Home() {
  const navigate = useNavigate();

  const { user,logout } = useAuth();

  const moveTo=(path)=>{
    navigate(path);
    
  }
  return (

   
<div style={{backgroundColor:'#0069BC',padding:'100px'}}>
{user.locationData? (''):logout()}

  <div style={{color:'#FFF'}}>
    <h1>Welcome, {user.locationData.first_name} {user.locationData.last_name}</h1>
  </div>
  <div style={{color:'#fff'}}>
    <p><i>Now you can place new order and check your recent orders quickly using this portal.</i></p>
  </div>
  <CardGroup style={{
    width: '40rem'
  }}>
<Card type='Button' onClick={()=>moveTo("/dashboard/order-history")}>
  <CardBody>
    
  <CardTitle>My Orders</CardTitle>
  <LocalMallRoundedIcon/>
  </CardBody>
</Card>
<Card style={{marginLeft:'5px',marginRight:'5px'}} type='Button' onClick={()=>moveTo("/dashboard/place-order")}>
  <CardBody>
    
  <CardTitle>Place New Order</CardTitle>
  <AddCardRoundedIcon/>
  </CardBody>
</Card>
<Card type='Button' onClick={()=>moveTo("/dashboard/my-account")}>
  <CardBody>
    
  <CardTitle>My Account</CardTitle>
  <PersonRoundedIcon/>
  </CardBody>
</Card>

  </CardGroup>
</div>
  );
}

export default Home;
