import { Button } from 'reactstrap';
import React from 'react';

function OrderComplete() {
   
  return (
   
<div style={{backgroundColor:'#0069BC',height:'400px',padding:'100px'}}>
 
  <div style={{color:'#FFF'}}>

    <h1>Thank you for your order!</h1>
  </div>
  <div style={{color:'#fff'}}>
    <p>You will recieve an email confirmation shortly.</p>

    <Button href="/dashboard/order-history">My Orders</Button>
  </div>
</div>
  );
}

export default OrderComplete;
