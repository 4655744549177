import { takeLatest, put, call } from "redux-saga/effects";

import { GET_HOTEL_LOCATIONS,ADD_LOCATION,DEL_LOCATION,EDIT_LOCATION} from "./actionTypes";

import {
  getHotelLocationsSuccess,
  getHotelLocationsFail,
  addLocationSuccess,
  addLocationFailure,
  delLocationSuccess,
  delProductFailure,
  editLocationSuccess,
  editLocationFailure
  
} from "./actions";

import { getHotelLocations,addLocation,delLocation ,editLocation} from "../../helpers/backend_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function* onAddLocation({ payload: data,navigate }) {

  
  try {
    const response = yield call(addLocation,data);
    console.log(response);
    toast(response.message);
    if(response.status===1){
      navigate("/dashboard/hotel-locations");

    }
    yield put(addLocationSuccess(response.payload));
  } catch (error) {
    toast(error.response.data.message);
    yield put(addLocationFailure(error.response));
  }
}

function* onGetHotelLocations() {

  
  try {
    const response = yield call(getHotelLocations);
    console.log(response);
  
    yield put(getHotelLocationsSuccess(response.payload));
  } catch (error) {
    yield put(getHotelLocationsFail(error.response));
  }
}



function* onEditLocation({ payload: data,navigate }) {

  
  try {
    const response = yield call(editLocation,data);
    console.log(response);
    toast(response.message);
    if(response.status===1){
      navigate("/dashboard/hotel-locations");

    }
    yield put(editLocationSuccess(response.payload));
  } catch (error) {
    toast(error.response.data.message);
    yield put(editLocationFailure(error.response));
  }
}

function* onDelLocation({ payload: data,navigate }) {

  
  try {
    const response = yield call(delLocation,data);
    console.log(response);
    toast(response.message);
    if(response.status===1){
      navigate(0);

    }
    yield put(delLocationSuccess(response.payload));
  } catch (error) {
    toast(error.response.data.message);
    yield put(delProductFailure(error.response));
  }
}

function* HotelSaga() {

  yield takeLatest(GET_HOTEL_LOCATIONS, onGetHotelLocations);
  yield takeLatest(ADD_LOCATION, onAddLocation);
  yield takeLatest(DEL_LOCATION, onDelLocation);
  yield takeLatest(EDIT_LOCATION, onEditLocation);

}

export default HotelSaga;
