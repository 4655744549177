
export const GET_MY_CART = "GET_MY_CART";
export const GET_MY_CART_SUCCESS = "GET_MY_CART_SUCCESS";
export const GET_MY_CART_FAIL = "GET_MY_CART_FAIL";

export const DEL_CART_ITEM="DEL_CART_ITEM";
export const DEL_CART_ITEM_SUCCESS="DEL_CART_ITEM_SUCCESS";
export const DEL_CART_ITEM_FAILURE="DEL_CART_ITEM_FAILURE";

export const ADD_TO_CART="ADD_TO_CART";
export const ADD_TO_CART_SUCCESS="ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAILURE="ADD_TO_CART_FAILURE";
