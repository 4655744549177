import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE
} from "./actionTypes";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';


// LOGIN
export const tryLogin = (data,navigate) => {
  return {
    type: LOGIN,
    payload: data,
    navigate:navigate,
  };
};

export const loginSuccess = (loginResponse) => {

  console.log("login successsss");
  toast(loginResponse.message);

  return {
    type: LOGIN_SUCCESS,
    payload:loginResponse,
  };
};

export const loginFailure = (error) => {

  return {
    type: LOGIN_FAILURE,
    payload:error,
  };
};


// FORGOT PASSWORD
export const forgotPassword = (data,navigate) => {
  return {
    type: FORGOT_PASSWORD,
    payload: data,
    navigate:navigate,
  };
};

export const forgotPasswordSuccess = (forgotPasswordResponse) => {

  console.log("forgotPasswordResponse successsss");
  toast(forgotPasswordResponse.message);

  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload:forgotPasswordResponse,
  };
};

export const forgotPasswordFailure = (error) => {

  return {
    type: FORGOT_PASSWORD_FAILURE,
    payload:error,
  };
};


// RESET PASSWORD

export const resetPassword = (data,navigate) => {
  return {
    type: RESET_PASSWORD,
    payload: data,
    navigate:navigate,
  };
};

export const resetPasswordSuccess = (resetPasswordResponse) => {

  console.log("resetPasswordResponse successsss");
  toast(resetPasswordResponse.message);

  return {
    type: RESET_PASSWORD_SUCCESS,
    payload:resetPasswordResponse,
  };
};

export const resetPasswordFailure = (error) => {

  return {
    type: RESET_PASSWORD_FAILURE,
    payload:error,
  };
};



//Verify OTP


export const verifyOTP = (data,authLogin,token) => {
  return {
    type: VERIFY_OTP,
    payload: data,
    authLogin:authLogin,
    token:token,
  };
};

export const verifyOTPSuccess = (verifyOTPResponse) => {

  console.log("verifyOTP successsss");
  toast(verifyOTPResponse.message);

  return {
    type: VERIFY_OTP_SUCCESS,
    payload:verifyOTPResponse,
  };
};

export const verifyOTPFailure = (error) => {

  return {
    type: VERIFY_OTP_FAILURE,
    payload:error,
  };
};