import {
  GET_POSTS,
  GET_POSTS_SUCCESS,
  GET_POSTS_FAIL,
  GET_POST_DETAILS,
  GET_POST_DETAILS_SUCCESS,
  GET_POST_DETAILS_FAIL,
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  DEL_PRODUCT,
  DEL_PRODUCT_SUCCESS,
  DEL_PRODUCT_FAILURE,
  EDIT_PRODUCT,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAILURE,
  ALLOCATE_PRODUCTS,
  ALLOCATE_PRODUCTS_SUCCESS,
  ALLOCATE_PRODUCTS_FAILURE,
  GET_ALLOCATED_PRODUCTS,
  GET_ALLOCATED_PRODUCTS_SUCCESS,
  GET_ALLOCATED_PRODUCTS_FAIL,
  DEL_ALLOCATED_PRODUCT,
  DEL_ALLOCATED_PRODUCT_SUCCESS,
  DEL_ALLOCATED_PRODUCT_FAILURE
} from "./actionTypes";

export const getPosts = () => {
  return {
    type: GET_POSTS,
  };
};

export const getPostsSuccess = (posts) => {
  return {
    type: GET_POSTS_SUCCESS,
    payload: posts,
  };
};

export const getPostsFail = (error) => {
  return {
    type: GET_POSTS_FAIL,
    payload: error,
  };
};

export const getPostDetails = (id) => {
  return {
    type: GET_POST_DETAILS,
    payload: id,
  };
};

export const getPostDetailsSuccess = (post) => {
  return {
    type: GET_POST_DETAILS_SUCCESS,
    payload: post,
  };
};

export const getPostDetailsFail = (error) => {
  return {
    type: GET_POST_DETAILS_FAIL,
    payload: error,
  };
};

export const addProduct = (data,navigate) => {
  return {
    type: ADD_PRODUCT,
    payload: data,
    navigate:navigate,
  };
};

export const addProductSuccess = (addProductResponse) => {
  return {
    type: ADD_PRODUCT_SUCCESS,
    payload:addProductResponse,
  };
};

export const addProductFailure = (error) => {
  return {
    type: ADD_PRODUCT_FAILURE,
    payload:error,
  };
};


export const editProduct = (data,navigate) => {
  return {
    type: EDIT_PRODUCT,
    payload: data,
    navigate:navigate,
  };
};

export const editProductSuccess = (editProductResponse) => {
  return {
    type: EDIT_PRODUCT_SUCCESS,
    payload:editProductResponse,
  };
};

export const editProductFailure = (error) => {
  return {
    type: EDIT_PRODUCT_FAILURE,
    payload:error,
  };
};





export const delProduct = (data,navigate) => {
  return {
    type: DEL_PRODUCT,
    payload: data,
    navigate:navigate,
  };
};

export const delProductSuccess = (delProductResponse) => {
  return {
    type: DEL_PRODUCT_SUCCESS,
    payload:delProductResponse,
  };
};

export const delProductFailure = (error) => {
  return {
    type: DEL_PRODUCT_FAILURE,
    payload:error,
  };
};




export const allocateProducts = (data,navigate) => {
  return {
    type: ALLOCATE_PRODUCTS,
    payload: data,
    navigate:navigate,
  };
};

export const allocateProductsSuccess = (allocateProductsResponse) => {
  return {
    type: ALLOCATE_PRODUCTS_SUCCESS,
    payload:allocateProductsResponse,
  };
};

export const allocateProductsFailure = (error) => {
  return {
    type: ALLOCATE_PRODUCTS_FAILURE,
    payload:error,
  };
};


export const getAllocatedProducts = (hotel_location_id,token) => {
  return {
    type: GET_ALLOCATED_PRODUCTS,
    payload: hotel_location_id,
    token:token,
  };
};

export const getAllocatedProductsSuccess = (allocatedProductList) => {
  return {
    type: GET_ALLOCATED_PRODUCTS_SUCCESS,
    payload: allocatedProductList,
  };
};

export const getAllocatedProductsFail = (error) => {
  return {
    type: GET_ALLOCATED_PRODUCTS_FAIL,
    payload: error,
  };
};





export const delAllocatedProduct = (data,navigate) => {
  return {
    type: DEL_ALLOCATED_PRODUCT,
    payload: data,
    navigate:navigate,
  };
};

export const delAllocatedProductSuccess = (delAllocatedProductResponse) => {
  return {
    type: DEL_ALLOCATED_PRODUCT_SUCCESS,
    payload:delAllocatedProductResponse,
  };
};

export const delAllocatedProductFailure = (error) => {
  return {
    type: DEL_ALLOCATED_PRODUCT_FAILURE,
    payload:error,
  };
};