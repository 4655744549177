import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE
} from "./actionTypes";


export const createOrder = (data,navigate) => {
  return {
    type: CREATE_ORDER,
    payload: data,
    navigate:navigate,
  };
};

export const createOrderSuccess = (createOrderResponse) => {
  return {
    type: CREATE_ORDER_SUCCESS,
    payload:createOrderResponse,
  };
};

export const createOrderFailure = (error) => {
  return {
    type: CREATE_ORDER_FAILURE,
    payload:error,
  };
};

export const getOrders= (hotel_location_id,token) => {
  return {
    type: GET_ORDERS,
    payload:hotel_location_id,
    token:token,

  };
};

export const getOrdersSuccess = (orders) => {
  return {
    type: GET_ORDERS_SUCCESS,
    payload: orders,
  };
};

export const getOrdersFail = (error) => {
  return {
    type: GET_ORDERS_FAIL,
    payload: error,
  };
};
