
import { useState } from 'react';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

function PlaceNewOrder() {
    const [state, setState] = useState({isPaneOpen: false});

  return (

    <div>
    <button onClick={() => setState({ isPaneOpen: true })}>
      Click me to open right pane!
    </button>
    
    <SlidingPane
      className="some-custom-class"
      overlayClassName="some-custom-overlay-class"
      width='500px'
      isOpen={state.isPaneOpen}
      title="My Cart"
      subtitle=""
      onRequestClose={() => {
        // triggered on "<" on left top click or on outside click
        setState({ isPaneOpen: false });
      }}
    >
      <div>And I am pane content. BTW, what rocks?</div>
      <br />
    </SlidingPane>
   
  </div>
  );
}

export default PlaceNewOrder;
