import { combineReducers } from "redux";

import PostReducer from "./products/reducer";
import LoginReducer from "./adminLogin/reducer";
import HotelLocationReducer from "./hotelLocations/reducer";
import OrdersReducer from "./orders/reducer";
import MyCartReducer from "./myCart/reducer";
const rootReducer = combineReducers({
  PostReducer,
  LoginReducer,
  HotelLocationReducer,
  OrdersReducer,
  MyCartReducer,
});

export default rootReducer;
