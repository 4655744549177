import {
  GET_POSTS,
  GET_POSTS_SUCCESS,
  GET_POSTS_FAIL,
  GET_POST_DETAILS,
  GET_POST_DETAILS_SUCCESS,
  GET_POST_DETAILS_FAIL,
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  DEL_PRODUCT,
  DEL_PRODUCT_SUCCESS,
  DEL_PRODUCT_FAILURE,
  EDIT_PRODUCT,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAILURE,
  ALLOCATE_PRODUCTS,
  ALLOCATE_PRODUCTS_SUCCESS,
  ALLOCATE_PRODUCTS_FAILURE,
  GET_ALLOCATED_PRODUCTS,
  GET_ALLOCATED_PRODUCTS_SUCCESS,
  GET_ALLOCATED_PRODUCTS_FAIL,
  DEL_ALLOCATED_PRODUCT,
  DEL_ALLOCATED_PRODUCT_SUCCESS,
  DEL_ALLOCATED_PRODUCT_FAILURE
} from "./actionTypes";

const initialState = {
  posts: [],
  post: {},
  addProductResponse: {},
  allocatedProductList: [],
  getAllocatedProductsResponse:{},
  editProductResponse: {},
  delProductResponse: {},
  loadingPosts: false,
  loadingAddProduct: false,
  loadingDelProduct: false,
  loadingEditProduct: false,
  loadingAllocateProducts: false,
  loadingGetAllocatedProducts: false,
  delAllocatedProductResponse: {},
  loadingDelAllocatedProduct: false,

  loadingPostDetails: false,
  error: {
    message: "",
    code:""
  },
};

const PostReducer = (state = initialState, action) => {


  switch (action.type) {

    case DEL_ALLOCATED_PRODUCT:
      state = { ...state, loadingDelAllocatedProduct: true };
      break;
    case DEL_ALLOCATED_PRODUCT_SUCCESS:
      state = { ...state, delAllocatedProductResponse: action.payload, loadingDelAllocatedProduct: false };

      break;
    case DEL_ALLOCATED_PRODUCT_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingDelAllocatedProduct: false,
      };
      break;
    case DEL_PRODUCT:
      state = { ...state, loadingDelProduct: true };
      break;
    case DEL_PRODUCT_SUCCESS:
      state = { ...state, delProductResponse: action.payload, loadingDelProduct: false };

      break;
    case DEL_PRODUCT_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingDelProduct: false,
      };
      break;

    case ADD_PRODUCT:
      state = { ...state, loadingAddProduct: true };
      break;
    case ADD_PRODUCT_SUCCESS:
      state = { ...state, addProductResponse: action.payload, loadingAddProduct: false };

      break;
    case ADD_PRODUCT_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingAddProduct: false,
      };
      break;
      case EDIT_PRODUCT:
        state = { ...state, loadingEditProduct: true };
        break;
      case EDIT_PRODUCT_SUCCESS:
        state = { ...state, editProductResponse: action.payload, loadingEditProduct: false };
  
        break;
      case EDIT_PRODUCT_FAILURE:
        state = {
          ...state,
          error: {
            message: "Error",
          },
          loadingEditProduct: false,
        };
        break;
    case GET_POSTS:
      state = { ...state, loadingPosts: true };
      break;
    case GET_POSTS_SUCCESS:
      state = { ...state, posts: action.payload, loadingPosts: false };
      break;
    case GET_POSTS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingPosts: false,
      };
      break;
    case GET_POST_DETAILS:
      state = { ...state, loadingPostDetails: true };
      break;
    case GET_POST_DETAILS_SUCCESS:
      state = { ...state, post: action.payload[0], loadingPostDetails: false };
      break;
    case GET_POST_DETAILS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingPostDetails: false,
      };
      break;


      case ALLOCATE_PRODUCTS:
        state = { ...state, loadingAllocateProducts: true };
        break;
      case ALLOCATE_PRODUCTS_SUCCESS:
        state = { ...state, allocateProductsResponse: action.payload, loadingAllocateProducts: false };
  
        break;
      case ALLOCATE_PRODUCTS_FAILURE:
        state = {
          ...state,
          error: {
            message: "Error",
          },
          loadingAllocateProducts: false,
        };
        break;
        case GET_ALLOCATED_PRODUCTS:
          state = { ...state, loadingGetAllocatedProducts: true };
          break;
        case GET_ALLOCATED_PRODUCTS_SUCCESS:
          state = { ...state, allocatedProductList: action.payload.payload, loadingGetAllocatedProducts: false };
          break;
        case GET_ALLOCATED_PRODUCTS_FAIL:
          state = {
            ...state,
            error: {
              message: action.payload.statusText,
          code: action.payload.status,
            },
            loadingGetAllocatedProducts: false,
          };
          break;
    
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default PostReducer;
