import {
  GET_HOTEL_LOCATIONS,
  GET_HOTEL_LOCATIONS_SUCCESS,
  GET_HOTEL_LOCATIONS_FAIL,
  ADD_LOCATION,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_FAILURE,
  DEL_LOCATION,
  DEL_LOCATION_SUCCESS,
  DEL_LOCATION_FAILURE,
  EDIT_LOCATION,
  EDIT_LOCATION_SUCCESS,
  EDIT_LOCATION_FAILURE
} from "./actionTypes";

const initialState = {
  hotelLocations: [],
  loading: false,
  error: {
    message: "",
  },
  delLocationResponse: {},
  editLocationResponse: {},
  addLocationResponse: {},
  loadingAddLocation: false,
  loadingDelLocation: false,
  loadingeditLocation: false,

};

const HotelLocationReducer = (state = initialState, action) => {


  switch (action.type) {


    case DEL_LOCATION:
      state = { ...state, loadingDelLocation: true };
      break;
    case DEL_LOCATION_SUCCESS:
      state = { ...state, delLocationResponse: action.payload, loadingDelLocation: false };

      break;
    case DEL_LOCATION_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingDelLocation: false,
      };
      break;

    case ADD_LOCATION:
      state = { ...state, loadingAddLocation: true };
      break;
    case ADD_LOCATION_SUCCESS:
      state = { ...state, addLocationResponse: action.payload, loadingAddLocation: false };

      break;
    case ADD_LOCATION_FAILURE:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingAddLocation: false,
      };
      break;
      case EDIT_LOCATION:
        state = { ...state, loadingeditLocation: true };
        break;
      case EDIT_LOCATION_SUCCESS:
        state = { ...state, editLocationResponse: action.payload, loadingeditLocation: false };
  
        break;
      case EDIT_LOCATION_FAILURE:
        state = {
          ...state,
          error: {
            message: "Error",
          },
          loadingeditLocation: false,
        };
        break;
    case GET_HOTEL_LOCATIONS:
      state = { ...state, loading: true };
      break;
    case GET_HOTEL_LOCATIONS_SUCCESS:
      state = { ...state, hotelLocations: action.payload, loading: false };
      break;
    case GET_HOTEL_LOCATIONS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
   
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default HotelLocationReducer;
