
export const LOGIN="LOGIN";
export const LOGIN_SUCCESS="ADD_PRODUCT_SUCCESS";
export const LOGIN_FAILURE="ADD_PRODUCT_FAILURE";



export const FORGOT_PASSWORD="FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS="FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE="FORGOT_PASSWORD_FAILURE";

export const RESET_PASSWORD="RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS="RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE="RESET_PASSWORD_FAILURE";


export const VERIFY_OTP="VERIFY_OTP";
export const VERIFY_OTP_SUCCESS="VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE="VERIFY_OTP_FAILURE";

