export const GET_POSTS = "GET_POSTS";
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS";
export const GET_POSTS_FAIL = "GET_POSTS_FAIL";

export const GET_ALLOCATED_PRODUCTS = "GET_ALLOCATED_PRODUCTS";
export const GET_ALLOCATED_PRODUCTS_SUCCESS = "GET_ALLOCATED_PRODUCTS_SUCCESS";
export const GET_ALLOCATED_PRODUCTS_FAIL = "GET_ALLOCATED_PRODUCTS_FAIL";


export const GET_POST_DETAILS = "GET_POST_DETAILS";
export const GET_POST_DETAILS_SUCCESS = "GET_POST_DETAILS_SUCCESS";
export const GET_POST_DETAILS_FAIL = "GET_POST_DETAILS_FAIL";

export const ADD_PRODUCT="ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS="ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE="ADD_PRODUCT_FAILURE";

export const DEL_PRODUCT="DEL_PRODUCT";
export const DEL_PRODUCT_SUCCESS="DEL_PRODUCT_SUCCESS";
export const DEL_PRODUCT_FAILURE="DEL_PRODUCT_FAILURE";

export const EDIT_PRODUCT="EDIT_PRODUCT";
export const EDIT_PRODUCT_SUCCESS="EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAILURE="EDIT_PRODUCT_FAILURE";

export const ALLOCATE_PRODUCTS="ALLOCATE_PRODUCTS";
export const ALLOCATE_PRODUCTS_SUCCESS="ALLOCATE_PRODUCTS_SUCCESS";
export const ALLOCATE_PRODUCTS_FAILURE="ALLOCATE_PRODUCTS_FAILURE";

export const DEL_ALLOCATED_PRODUCT="DEL_ALLOCATED_PRODUCT";
export const DEL_ALLOCATED_PRODUCT_SUCCESS="DEL_ALLOCATED_PRODUCT_SUCCESS";
export const DEL_ALLOCATED_PRODUCT_FAILURE="DEL_ALLOCATED_PRODUCT_FAILURE";
