import {
    UncontrolledDropdown,
    DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'; import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
 import logo from './aarfidLogo.png';
// import logo from '../../public/logo192.png'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
function PublicHeader() {

    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <div className="headerLayout" style={{
            backgroundColor: '#FFFFFF', padding: '20px', position: 'sticky', top: '0'
        }}>
            <Navbar color="white" light expand="md">
                <NavbarBrand href="/"> <img src={logo} alt="Logo" /></NavbarBrand>
                <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="justify-content-end" style={{ width: "100%" }} navbar>
                        <NavItem>
                            <NavLink href="/">Welcome</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/login">Login</NavLink>
                        </NavItem>
                       
                        <NavItem>
                            <NavLink href="/">            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </div >
    );
}

export default PublicHeader;
