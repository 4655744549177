import React, { useMemo } from 'react';
import DataTable,{ createTheme } from 'react-data-table-component';
import {  Container, Row, Col } from "react-bootstrap";
import { Circles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getOrders } from '../../store/orders/actions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useCallback } from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
const breadCrumbCss = {
  paddingTop: '10px',
  fontSize: '20px',
  width: 'fit-content',
  paddingLeft: '30px',
  fontWeight:'500'
}
createTheme('data-table', {
  text: {
    primary: '#000',
    secondary: '#2aa198',
  },
  background: {
    default: '#FFF',
  },
  context: {
    background: '#cb4b16',
    text: '#000',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'dark');
const headingCss = {
  fontSize: '20px',
  width: 'fit-content',
  marginBottom: '20px',
}


function OrderHistory() {
  const navigate = useNavigate();
  const { orders, loading } = useSelector((state) => state.OrdersReducer);


  const columns = useMemo(()=>[
    {
      name: 'Order #',
      selector: row => row.order_id,
      sortable: true,
    },
    {
      name: 'Order Date & Time(GMT)',
      grow:2,
      selector: row => new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit',timeZone: 'GMT'}).format(row.activity_timeline[0].timestamp),
    },

    {
      name: 'Ship To Address',
      grow:2,
      cell: row=>(
        <div style={{padding:"5px"}}>
      <p className='no-margin-orders'>Attn: {user.locationData.first_name} {user.locationData.last_name}</p>
     <p className='no-margin-orders'>{user.locationData.ship_to_street1} {user.locationData.ship_to_street2}</p> 
     <p className='no-margin-orders'> {user.locationData.ship_to_city}, {user.locationData.ship_to_state} {user.locationData.ship_to_zip}</p>
     <p className='no-margin-orders'> {user.locationData.ship_to_country}</p>

        </div>
      ),
    },
    {
      name: 'Status',
      selector: row => row.status,
      grow:1,
    },
  
    {
      name: 'Total Price',
      grow:1.5,  
      center:true, 
      selector: row =>"$"+ row.accounting.total_price.toFixed(2),
  
    },
  
    {
      name: 'Action',
      button: true,
      grow:2,
      cell: row =>(<div> <button onClick={()=>viewOrder(row.index,row.order_object_id)} ><VisibilityIcon/></button>
      </div>),
  
    },
  ],[],);
  
  

  const viewOrder=(index,order_object_id)=>{
    navigate('/dashboard/order-detail',{state:{index:index,orderId:order_object_id}});
    
  }

  const { user } = useAuth();


  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrders(user.locationId,user.locationData.token));
  }, []);

  return (
    <div style={{ backgroundColor: '#F1F2F4' }}>
      <p style={breadCrumbCss}>My Orders</p>

      <ToastContainer />

      <div className='order-history-table' style={{
        backgroundColor: '#FFF', marginTop: '10px', display: 'block', padding: 30
      }}>

        <div style={{ border: '1px solid #c3c3c3'}}>

        {loading ? (
          <div className="loader" >
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              timeout={3000} //3 secs
            />
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={orders}
            theme=""
            pagination
            highlightOnHover
            fixedHeader
            sortIcon={<FilterListIcon />}
          />
        )}
        </div>

      </div>
    </div>
  );
}

export default  OrderHistory;
